@import '../variables.scss';

.midSectionContent {
  display: flex;
  align-items: center;
  background-color: #fff;
  color: $nav-text;
  padding: 30px 10px;
  text-align: center;
  justify-content: center;
  margin: 0px 15px 20px 15px;
  border: 1px solid $secondary;
  
  div {
    width: 80%;
    margin-right: 10px;
    @media only screen and (max-width: 1280px) {
      margin-bottom: 20px;
    }
  }
  p {
    font-size: 18px;
    line-height: 30px;
    font-family: $main-font;
    font-weight: 400;
    a {
      text-decoration: underline;
    }
  }
  .bannerLink {
    margin: 0px 40px 0px 3px;
    @media only screen and (max-width: 1280px) {
      margin: unset;
      margin-bottom: 18px;
    }
  }
  @media only screen and (max-width: 1280px) {
    margin: 0px 60px 20px 60px;
    flex-direction: column;
    p {
      margin: unset;
    }
   }
   @media only screen and (max-width: 450px) {
    margin: 15px 20px 20px 20px;
    flex-direction: column;
    p {
      margin: unset;
    }
   }
}

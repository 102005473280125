@import '../variables.scss';

.showReadMore {
  padding: 0px 12px 12px 12px;
  max-width: 1200px;
  h1 {
    text-align: left;
    margin-top: 0px;
    margin-bottom: 0px;
    @media only screen and (max-width: 450px) {
      text-align: center;
      min-height: 60px;
    }
  }
  p, span {
    font-size: 16px;
    color: $main-text;
    line-height: 24px;
    font-family: $main-font;
    font-weight: 400;
    margin-top: 0px;
  }
  .showButton {
    text-align: right;
    margin-right: 10px;
    margin-left: 10px;
    @media only screen and (min-width: 450px) {
      display: none;
    }
  }
  .showLess {
    position: absolute;
    bottom: -25px;
    right: 25px;
  }
  .intro {
    position: relative;
    a {
      text-decoration: underline;
    }
    @media only screen and (max-width: 450px) {
      margin-bottom: 40px;
      svg {
        margin-left: 7px;
        margin-bottom: 3px;
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    margin-top: 65px;
    padding: 0px 42px 12px 42px;
   }
   @media only screen and (max-width: 450px) {
    margin-top: 75px;
    padding: 0px 2px 0px 2px;
  }
}


@import '../variables.scss';

.buttonsContainer {
  width: 230px;
  display: flex;
  justify-content: space-between;
  margin: 25px 0px 24px 25px;
  color: $main-text;
  font-family: $main-font;
  font-weight: normal;
  @media only screen and (max-width: 1280px) {
    top: 50px;
    position: fixed;
    z-index: 100;
    margin: unset;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 5px;
    align-content: center;
    background-color: $main-background;

  }
  a {
    align-self: center;
  }
  span {
    border: 1px solid $secondary;
    padding: 5px 10px;
    background-color: #fff;
    margin-right: 6px;
    align-content: center;
    cursor: pointer;
    @media only screen and (max-width: 450px) {
      min-height: 33px;
    }
  }
  span:hover {
    background: $nav-text;
    color: #FFF;
  }
  .firstSpan {
    margin-left: 10px;
  }
  .categoryActive {
    background-color: $secondary;
  }
  .categoryActive:hover {
    background-color: $secondary
  }
}

.russianFont {
  font-size: 14px;
}
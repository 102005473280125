@import '../variables.scss';

.performerContainer {
  width: 100%;
}
.similarPerformers {
  display: block;
  color: #111;
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 10px;
  font-weight: 700;
  min-height: 160px;
}

.performers {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0px 10px;
  @media only screen and (max-width: 1280px) {
    margin: 0px 42px;
    justify-content: space-around;
  }
  @media only screen and (max-width: 450px) {
    margin: 0px 10px;
  }
  .performer {
    width: 24.5%;
    margin-bottom: 20px;
    margin-left: 5px;
    @media only screen and (max-width: 1280px) {
      width: 48%;
      margin-left: unset
    }
    @media only screen and (max-width: 450px) {
      margin-bottom: 5px;
      width: 49.5%;
    }
  }
}

.advancedSearchH1 {
    text-align: left;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 12px;
    @media only screen and (max-width: 1280px) {
      margin-top: 20px;
      margin-left: 30px;
     }
     @media only screen and (max-width: 450px) {
      margin-top: 20px;
      margin-left: 10px;
     }
}




@import '../variables.scss';

.paginationContainer {
  display: flex;
  justify-content: center;
  font-family: 'Niramit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  @media only screen and (max-width: 500px) {
    margin-top: 20px;
    }
  .paginationItem {
    margin: 0px 7px;
    border: 1px solid $secondary;
    padding: 4px 0px;
    color: $nav-text;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    @media only screen and (max-width: 500px) {
      margin: 0px 3px;
      }
  }
  .paginationLarge {
    margin: 0px 5px;
    border: 1px solid $secondary;
    padding: 4px 0px;
    color: $nav-text;
    width: 37px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }
  .paginationEllipsis {
    padding-top: 8px;
    color: $nav-text;
    width: 20px;
    text-align: center;
  }

  .activePage {
    display: inline-block;
    color: #FFF;
    background: $primary;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 0 0 4px $primary,
                inset 0 0 0 0.1px $secondary;
  }
}
@import '../variables.scss';

.user {
  position: relative;
  cursor: pointer;
}

.hidden {
  display: none!important;
}

.performerImage {
  overflow: hidden;
  :hover {
    transform: scale(1.05);
    transition: transform .3s;
  }
  :not(:hover) {
    transform: scale(1.0);
    transition: transform .3s;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.performerVideo {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%; /* 16:9 aspect ratio (change as needed) */
  overflow: hidden;
  cursor: pointer;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.userInfo {
  position: absolute;
  bottom: 0;
  height: 30px;
  width: 100%;
  background: $nav-text;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-family: $main-font;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  @media only screen and (max-width: 450px) {
    font-size: 10px;
    line-height: 15px;
    height: 20px;
  }
  @media only screen and (max-width: 360px) {
    font-size: 8px;
  }
  .userName {
    margin-left: 5px;
    svg {
      height: 12px;
      margin-right: 5px;
      @media only screen and (max-width: 450px) {
        height: 8px;
      }
    }
  }
  .userRating {
    margin-right: 5px;
    font-size: 16px;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 450px) {
      font-size: 10px;
      line-height: 15px;
    }
    .heartContainer {
      margin-top: 5px;
      margin-left: 3px;
      @media only screen and (max-width: 450px) {
        margin-top: 1px;
        svg {
          width: 8px;
          height: 8px;
        }
      }
    }
  }
  .userRatingVideo {
    margin-right: 5px;
    font-size: 16px;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 450px) {
      font-size: 10px;
      line-height: 15px;
    }
    .heartContainer {
      margin-top: 5px;
      margin-left: 3px;
      @media only screen and (max-width: 450px) {
        margin-top: 1px;
        svg {
          width: 8px;
          height: 8px;
        }
      }
    }
  }
  .videoTest {
    display: block;
  }
  .videoIcon {
    svg {
      position: absolute;
      right: 10px;
      top: -20px;
      width: 35px;
      @media only screen and (max-width: 450px) {
        width: 30px;
        top: -25;
        right: 5px;
      }
    }
  }
}



